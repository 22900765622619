<template>
  <v-card data-app>
    <v-card-title>
      Donations
      <v-spacer></v-spacer>
      <label class="switch">
        <input type="checkbox" v-model="isLiveMode" checked />
        <span class="slider round"></span>
      </label>
      <span class="pr-10"> {{ isLiveMode ? "Live Mode" : "Test Mode" }} </span>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <b-form-select
        v-model="filterBy"
        placeholder="Filter By"
        :options="options"
        class="mb-3 reduce-screen"
      ></b-form-select>
      <v-col class="text-center" v-if="filterBy === 'Custom Date'">
        <input type="date" v-model="start_date" />
        <input type="date" v-model="end_date" />
        <button type="button" class="filter-button" @click="fetchAllData" :disabled="!(start_date && end_date)">
          Apply Filter
        </button>
      </v-col>
      <v-col class="text-center" cols="12" sm="6" lg="1">
        <download-excel
          class="btn btn-default"
          :data="filteredData"
          :fields="json_fields"
          worksheet="My Worksheet"
          name="donations.xls"
        >
          Export
        </download-excel>
      </v-col>
    </v-card-title>
    <v-data-table
      :items="filteredData"
      :headers="headers"
      :search="search"
      :hide-default-footer="true"
      :items-per-page="-1"
    >
      <template #item.donation_id="{ value }">
        <router-link :to="`/donationView/${value}`"> {{ value }} </router-link>
      </template>
      <template #item.amount="{ value }">
        <span>{{ value | currency }} </span>
      </template>
    </v-data-table>
    <div class="button-center">
    <button
      class="filter-button"
      style="padding: 10px 20px 10px 20px"
      @click="fetchDonations(-1)"
    >
      Previous
    </button>
    <button
      class="filter-button"
      style="padding: 10px 20px 10px 20px"
      @click="fetchDonations(1)"
    >
      Next
    </button>
    </div>
  </v-card>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Vue from "vue";
import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);
Vue.filter("currency", function (value) {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  });
  return formatter.format(value);
});

export default {
  name: "donations",
  data() {
    return {
      start_date: null,
      end_date: null,
      isLiveMode: true,
      filteredData: [],
      posts: [],
      errors: [],
      search: "",
      options: [
        "Filter By",
        "Today",
        "Yesterday",
        "This Week",
        "Last Week",
        "This Month",
        "Last Month",
        "This Year",
        "Custom Date",
        "All",
      ],
      filterBy: "Filter By",
      headers: [
        {
          text: "",
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: "Id",
          value: "donation_id",
        },
        {
          text: "Project Name",
          value: "project_name",
        },
        {
          text: "Donor Name",
          value: "donor_name",
        },
        {
          text: "PAN",
          value: "pan_no",
        },
        {
          text: "Payment Date",
          value: "created_at",
        },
        {
          text: "Amount",
          value: "amount",
        },
        {
          text: "Status",
          value: "status",
        },
      ],

      json_fields: {
        Id: "donation_id",
        "Project Name": "project_name",
        "Payment Date": "created_at",
        Amount: "amount",
        Status: "status",
        "Referred By": "referred_by",
        "Donor Id": "donorId",
        "Donor Name": "donor_name",
        PAN: "pan_no",
        Phone: "phone",
        Email: "email",
        "Indian Passport Holder": "indian_passport_holder",
        Gender: "gender",
        DOB: "dob",
        Address: "address",
        City: "city",
        District: "district",
        State: "state",
        "Postal Code": "postal_code",
        Country: "country",
      },
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      paginationCount: 0,
    };
  },
  // Fetches posts when the component is created.
  created() {
    this.fetchDonations(0);
    this.isLiveMode = localStorage.getItem("isLiveMode") === "true";
  },
  watch: {
    filterBy() {
      this.fetchAllData();
    },
    isLiveMode() {
      localStorage.setItem("isLiveMode", this.isLiveMode);
      ApiService.setHeader();
      ApiService.post("/modeSwitch/1", {
        isLivemode: this.isLiveMode ? "1" : "0",
      }).then(() => {
        this.fetchDonations(0);
      });
    },
  },
  methods: {
    fetchDonations(count) {
      this.paginationCount += count
      ApiService.setHeader();
      ApiService.get("/donationview/0/" + this.paginationCount).then(
        (response) => {
          // JSON responses are automatically parsed.
          this.posts = response.data;
          const data = JSON.parse(JSON.stringify(this.posts));

          this.json_data = data.donationDetails;
          this.filteredData = [...this.json_data];
        }
      );
    },
    filterByDate(isCurrentDate) {
      let currentDate = new Date();
      if (!isCurrentDate) {
        currentDate.setDate(currentDate.getDate() - 1);
      }
      currentDate = currentDate.toISOString().slice(0, 10);
      this.filteredData = [];
      this.json_data.forEach((item) => {
        const date = item.created_at.slice(0, 10);
        if (date === currentDate) {
          this.filteredData.push(item);
        }
      });
    },
    filterByMonth(isCurrentMonth) {
      this.filteredData = [];
      const currentDate = new Date();
      let month = currentDate.getMonth();
      let year = currentDate.getFullYear();
      if (!isCurrentMonth) {
        month -= 1;
        if (month < 0) {
          month = 12;
          year -= 1;
        }
      }
      this.json_data.forEach((item) => {
        const itemMonth = new Date(item.created_at).getMonth();
        const itemYear = new Date(item.created_at).getFullYear();
        if (itemMonth === month && itemYear === year) {
          this.filteredData.push(item);
        }
      });
    },
    weekDiff(d1, d2) {
      let dif = Math.round(d1 - d2);
      return Math.ceil(dif / 1000 / 60 / 60 / 24 / 7);
    },
    getLastWeek() {
      let today = new Date();
      let lastWeek = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 7
      );
      return lastWeek;
    },
    filterByWeek(isCurrentWeek) {
      this.filteredData = [];
      var prevWeekDate = this.getLastWeek();
      this.json_data.forEach((item) => {
        if (isCurrentWeek) {
          if (this.weekDiff(new Date(item.created_at), prevWeekDate) > 1) {
            this.filteredData.push(item);
          }
        } else {
          if (this.weekDiff(new Date(item.created_at), prevWeekDate) == 1) {
            this.filteredData.push(item);
          }
        }
      });
    },
    filterByYear() {
      let currentDate = new Date().getFullYear();
      this.filteredData = [];
      this.json_data.forEach((item) => {
        let itemDate = new Date().getFullYear();
        if (itemDate === currentDate) {
          this.filteredData.push(item);
        }
      });
    },
    filterByCustomDate() {
      const startDate = new Date(this.start_date);
      const endDate = new Date(this.end_date);
      this.filteredData = [];
      this.json_data.forEach((item) => {
        const date = new Date(item.created_at.slice(0, 10));

        if (date >= startDate && date <= endDate) {
          this.filteredData.push(item);
        }
      });
    },
    fetchAllData() {
      ApiService.setHeader();
      ApiService.get("/donationReports/").then((response) => {
        // JSON responses are automatically parsed.
        this.posts = response.data;
        const data = JSON.parse(JSON.stringify(this.posts));
        this.json_data = data.donationDetails;
        this.filteredData = [...this.json_data];
        if (this.filterBy === "Custom Date") {
          this.filterByCustomDate();
        }
        this.filterHandler();
      });
    },
    filterHandler() {
      switch (this.filterBy) {
        case "Today":
          this.filterByDate(true);
          break;
        case "Yesterday":
          this.filterByDate(false);
          break;
        case "Last Month":
          this.filterByMonth(false);
          break;
        case "This Month":
          this.filterByMonth(true);
          break;
        case "Last Week":
          this.filterByWeek(false);
          break;
        case "This Week":
          this.filterByWeek(true);
          break;
        case "This Year":
          this.filterByYear();
          break;
      }
    },
  },
};
</script>

<style scoped>
/* Removes the clear button from date inputs */
input[type="date"]::-webkit-clear-button {
  display: none;
}

/* Removes the spin button */
input[type="date"]::-webkit-inner-spin-button {
  display: none;
}

/* Always display the drop down caret */
input[type="date"]::-webkit-calendar-picker-indicator {
  color: #2c3e50;
}

/* A few custom styles for date inputs */
input[type="date"] {
  appearance: none;
  margin-right: 20px;
  -webkit-appearance: none;
  color: #95a5a6;
  font-family: "Helvetica", arial, sans-serif;
  font-size: 18px;
  border: 1px solid #ecf0f1;
  background: #ecf0f1;
  padding: 2px;
  display: inline-block !important;
  visibility: visible !important;
}

input[type="date"],
focus {
  color: #95a5a6;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.filter-button {
  font-size: 14px;
  background: #ecf0f1;
  padding: 5px;
  margin: 10px;
}

.filter-button:disabled {
  pointer-events: none;
}

.button-center{
  text-align: center;
}

.reduce-screen{
width: 25%;
}
</style>
